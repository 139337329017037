<template>
  <main class="mx-auto w-full max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20">
    <header class="mb-6 flex flex-wrap items-baseline justify-between">
      <h1 class="my-1 text-2xl font-bold">Start</h1>
      <p class="text-sm">
        We recommend consulting with your space’s <strong>building engineer</strong>, <strong>human resources manager</strong>, and <strong>health and safety officer</strong> to complete this process.
      </p>
    </header>
    <div class="p-4 md:p-6 bg-gray-100 rounded-lg flex flex-wrap sm:flex-nowrap justify-between gap-6">
      <dynamic-form :form="form" @change="valueChanged" class="w-full sm:w-1/2 flex-grow">
        <template v-slot:name="{ control, onChange, onFocus, onBlur }">
          <div class="sm:grid sm:grid-cols-3 sm:gap-5 mb-4 sm:mb-1 items-start">
            <label for="name" class="sm:col-span-3 md:col-span-1 py-1 flex flex-wrap items-center block text-base font-semibold text-gray-800">
              <svg class="flex-shrink mr-2 w-5 h-auto" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.773 6.02306L9.38651 11.1941L1 6.02306V13.6781L9.38651 18.8491L17.773 13.6781V6.02306Z" stroke="#F42272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path d="M9.38989 11.1941V18.8521" stroke="#F42272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path d="M1.0061 6.02305L9.39261 0.852051L17.7791 6.02305" stroke="#F42272" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
              Space name
            </label>
            <div class="sm:col-span-3 md:col-span-2">
              <input
                :id="control.name"
                v-if="control"
                v-model="control.value"
                type="text"
                :name="control.name"
                autocomplete=""
                minlength="1"
                maxlength="100"
                class="block max-w-lg w-full shadow-sm focus:border-pink-500 focus:ring-1 focus:ring-pink-500 sm:text-sm border-gray-300 rounded-md"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
              />
              <p class="mt-2 text-xs text-gray-600">Use a descriptive name to easily identify the space.</p>
            </div>
            <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
          </div>
        </template>
        <template v-slot:description="{ control, onChange, onFocus, onBlur }">
          <div class="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start sm:pt-5">
          <label for="description" class="sm:col-span-3 md:col-span-1 py-1 block text-sm font-medium text-gray-800 whitespace-normal">
            Description <span class="text-xs text-gray-600">(optional)</span>
          </label>
          <div class="sm:col-span-3 md:col-span-2">
            <textarea
              v-if="control"
              v-model="control.value"
              :id="control.name"
              :name="control.name"
              rows="3"
              maxlength="100"
              class="max-w-lg shadow-sm block w-full focus:border-pink-500 focus:ring-1 focus:ring-pink-500 sm:text-sm border border-gray-300 rounded-md"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            ></textarea>
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div>
          </div>
        </template>
      </dynamic-form>
      <div class="w-full sm:w-1/2 flex-grow p-2">
        <header class="flex flex-wrap items-baseline">
          <svg class="mr-2 w-5 h-auto" width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.11328 24.3667H13.1758V11.45C13.1758 11.176 13.0748 10.9131 12.8951 10.7194C12.7154 10.5256 12.4716 10.4167 12.2174 10.4167H4.07161C3.81745 10.4167 3.57369 10.5256 3.39397 10.7194C3.21425 10.9131 3.11328 11.176 3.11328 11.45V24.3667ZM3.11328 24.3667H1.67578M16.0508 24.3667H21.8008M16.0508 24.3667H18.9258V19.7167C18.9258 19.3056 18.7743 18.9114 18.5047 18.6207C18.2352 18.33 17.8695 18.1667 17.4883 18.1667C17.107 18.1667 16.7414 18.33 16.4718 18.6207C16.2022 18.9114 16.0508 19.3056 16.0508 19.7167V24.3667ZM21.8008 24.3667V2.6667C21.8008 2.25561 21.6493 1.86137 21.3797 1.57068C21.1102 1.28 20.7445 1.1167 20.3633 1.1167H11.7383C11.357 1.1167 10.9914 1.28 10.7218 1.57068C10.4522 1.86137 10.3008 2.25561 10.3008 2.6667V7.3167H11.7383M21.8008 24.3667H23.2383M10.3008 4.2167H14.6133M3.11328 13.5167H5.26953M8.86328 24.3667H5.98828V21.2667C5.98828 20.8556 6.13973 20.4614 6.40932 20.1707C6.6789 19.88 7.04453 19.7167 7.42578 19.7167C7.80703 19.7167 8.17266 19.88 8.44225 20.1707C8.71183 20.4614 8.86328 20.8556 8.86328 21.2667V24.3667Z" stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <h2 class="text-xl font-medium text-gray-800">{{ buildingName }}</h2>
        </header>
        <p class="text-gray-600 mt-1">{{ buildingAddress }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { reactive } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import { createFormFields, validations } from '@/models/create-space-mapping';

export default {
  name: 'SpaceFormPageOne',
  computed: {
    buildingName() {
      return this.selectedBuilding?.name;
    },
    buildingAddress() {
      return this.selectedBuilding?.address;
    },
    ...mapGetters(['selectedBuilding'])
  },
  props: {
    currentSpace: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const formValues = reactive({});
    const initialValues = {
      name: props.currentSpace.name,
      description: props.currentSpace.description
    };
    const fields = createFormFields(0, initialValues);
    const form = computed(() => ({
      id: 'space-creation-page-one',
      fields: fields
    }));

    function valueChanged(values) {
      Object.assign(formValues, values);
      context.emit('formValues', formValues);
    }

    return {
      form,
      formValues,
      valueChanged,
      validations
    };
  },
};
</script>